var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LazyHydrate',{attrs:{"when-visible":""}},[_c('Carousel',_vm._b({staticClass:"products-carousel",attrs:{"dotContainerFullWidthMobile":_vm.dotContainerFullWidthMobile,"loop":_vm.loop,"showDots":_vm.$b.mt,"arrowsBgHalfRounded":_vm.arrowsBgHalfRounded,"id":_vm.listId}},'Carousel',Object.assign({}, _vm.$b.props({
				slidesPerPage: { m: 2, t: 3, d: 4 },
				gutter: { m: 2 },
				peek: { m: 8 },
				showArrows: { d: true },
				center: { m: true },
			}),
			this.$attrs),false),_vm._l((_vm.products),function(product,i){return _c('div',{key:i,staticClass:"d-flex justify-center"},[_c('ProductCard',{attrs:{"type":_vm.cardType,"product":product,"list-id":_vm.listId,"list-index":i,"offer":_vm.offer,"small":_vm.$b.m,"mobileBtn":_vm.mobileBtn,"forMobileShop":!_vm.$b.d}})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }