<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	props: {
		products: Array,
		listId: String,
		offer: Object,
		loop: { type: Boolean, default: false },
		dotContainerFullWidthMobile: { type: Boolean, default: false },
		arrowsBgHalfRounded: { type: Boolean, default: false },
		cardType: String,
	},
	data() {
		return {
			mobileBtn: this.$srv('productCardType.btnMobile', 'noBtn') == 'noBtn' ? false : true,
		}
	},
	mounted() {
		if (this.products.length && this.listId) {
			this.$eventer().trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<LazyHydrate when-visible>
		<Carousel
			:dotContainerFullWidthMobile="dotContainerFullWidthMobile"
			class="products-carousel"
			:loop="loop"
			:showDots="$b.mt"
			:arrowsBgHalfRounded="arrowsBgHalfRounded"
			v-bind="{
				...$b.props({
					slidesPerPage: { m: 2, t: 3, d: 4 },
					gutter: { m: 2 },
					peek: { m: 8 },
					showArrows: { d: true },
					center: { m: true },
				}),
				...this.$attrs,
			}"
			:id="listId"
		>
			<div class="d-flex justify-center" v-for="(product, i) of products" :key="i">
				<ProductCard
					:type="cardType"
					:product="product"
					:list-id="listId"
					:list-index="i"
					:offer="offer"
					:small="$b.m"
					:mobileBtn="mobileBtn"
					:forMobileShop="!$b.d"
				/>
			</div>
		</Carousel>
	</LazyHydrate>
</template>

